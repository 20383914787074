import React from "react";

import { useTranslation } from 'gatsby-plugin-react-i18next';

const Card = (props) => {
	const { t } = useTranslation();

	function handleClick() {
		props.setCurrentPdf(props.link, props.title);
	}

	const btnClassName =
		"hover:opacity-80 text-white text-xs bg-green rounded-3xl py-2.5 px-3.5 focus:outline-green m-0 ml-6";

	return (
		<li className={"mb-5 border-b pb-2"}>
			<div className={"flex items-center justify-between"}>
				<p className={"text-sm"}>{props.title}</p>
				{props.guidePage ? (
					<button onClick={handleClick} className={btnClassName}>
						{t("Ava")}
					</button>
				) : (
					<a
						href={props.link}
						target={"_blank"}
						rel="noreferrer"
						className={btnClassName}
					>
						{t("Ava")}
					</a>
				)}
			</div>
		</li>
	);
};

export default Card

